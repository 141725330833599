import React from 'react';
// import ReactDOM from 'react-dom'; // For React 17
import { createRoot } from 'react-dom/client'; // For React 18
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import { AuthContextProvider } from './contexts/authContext';
import './i18n';
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
    /**
     * refetchOnWindowFocus: automatically requests fresh data in the background if user leaves the app and returns to stale data.
     * refetchOnmount: if true, refetch on mount if the data is stale.
     * refetchOnReconnect: if true, refetch on reconnect if the data is stale.
     * retry: if true, failed queries will retry infinitely.
     * staleTime: the time in milliseconds after data is considered stale. Defaults to 0.
     */
    defaultOptions: {
        queries: {
            staleTime: 0,
        },
    },
});

const children = (
	<QueryClientProvider client={queryClient}>
		<AuthContextProvider>
			<ThemeContextProvider>
				<Router basename='/'>
					<React.StrictMode>
						<App />
					</React.StrictMode>
				</Router>
			</ThemeContextProvider>
		</AuthContextProvider>
	</QueryClientProvider>
);

const container = document.getElementById('root');

// ReactDOM.render(children, container); // For React 17
createRoot(container as Element).render(children); // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
