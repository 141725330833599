import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import { Dashboard } from '../components/icon/material-icons';
import {
	componentPagesMenu,
	dashboardPagesMenu,
	demoPagesMenu,
	gettingStartedPagesMenu,
	pageLayoutTypesPagesMenu,
	dashboardMenu,
	dashboardMenuSubPages
} from '../menu';
import Login from '../pages/presentation/auth/Login';

const SETTINGS = {
	// CATEGORY: lazy(() => import('../pages/pujaPathPages/setting/category/CategoryPage')),
	// LOCATION: lazy(() => import('../pages/pujaPathPages/setting/location/LocationPage')),
	// LANGUAGE: lazy(() => import('../pages/pujaPathPages/setting/language/LanguagePage')),
	// MAGAZINETYPE: lazy(() => import('../pages/pujaPathPages/setting/magazineType/MagazineTypePage')),

};

const MAINPAGES = {

	// live
	// DASHBOARD: lazy(() => import('../pages/pujaPathPages/dashboard/DashboardPage')),

	// // live
	// LIVE: lazy(() => import('../pages/pujaPathPages/live/Live')),

	// cms
	CATEGORY: lazy(() => import('../pages/pujaPathPages/category/CategoryPage')),
	ADDCATEGORY: lazy(() => import('../pages/pujaPathPages/category/AddCategoryPage')),
	EditCATEGORY: lazy(() => import('../pages/pujaPathPages/category/EditCategoryPage')),

	// // puja
	PROCESSSLIDER: lazy(() => import('../pages/pujaPathPages/processSlider/ProcessSliderPage')),
	ADDPROCESSSLIDER: lazy(() => import('../pages/pujaPathPages/processSlider/AddProcessSlider')),
	EDITPROCESSSLIDER: lazy(() => import('../pages/pujaPathPages/processSlider/EditProcessSlider')),

	// // samagri
	// SAMAGRI: lazy(() => import('../pages/pujaPathPages/samagri/SamagriPage')),
	// ADDSAMAGRI: lazy(() => import('../pages/pujaPathPages/samagri/AddSamagriPage')),
	// EDITSAMAGRI: lazy(() => import('../pages/pujaPathPages/samagri/EditSamagriPage')),

	// // package
	// PACKAGE: lazy(() => import('../pages/pujaPathPages/pujas/package/PackagePage')),
	// ADDPACKAGE: lazy(() => import('../pages/pujaPathPages/pujas/package/AddPackagePage')),
	// EDITPACKAGE: lazy(() => import('../pages/pujaPathPages/pujas/package/EditPackagePage')),

	// // astrology
	// ASTROLOGY: lazy(() => import('../pages/pujaPathPages/astrology/AstrologyPage')),
	// ADDASTROLOGY: lazy(() => import('../pages/pujaPathPages/astrology/AddAstrologyPage')),
	// EDITASTROLOGY: lazy(() => import('../pages/pujaPathPages/astrology/EditAstrologyPage')),
	// // puja-kit
	TESTIMONIAL: lazy(() => import('../pages/pujaPathPages/testimonial/TestimonialPage')),
	ADDTESTIMONIAL: lazy(() => import('../pages/pujaPathPages/testimonial/AddTestimonialPage')),
	EDITTESTIMONIAL: lazy(() => import('../pages/pujaPathPages/testimonial/EditTestimonialPage')),

	// // samagri-package
	// STORY: lazy(() => import('../pages/pujaPathPages/story/StoryPage')),
	// ADDSAMAGRIPACKAGE: lazy(() => import('../pages/pujaPathPages/pujaKit/samagripackage/AddSamagriPackagePage')),
	// EDITSAMAGRIPACKAGE: lazy(() => import('../pages/pujaPathPages/pujaKit/samagripackage/EditSamagriPackagePage')),

	// // religious-tour
	SETTING: lazy(() => import('../pages/pujaPathPages/settings/EditSettingPage')),
	// ADDRELIGOUSTOUR: lazy(() => import('../pages/pujaPathPages/religiousTour/AddReligiousTourPage')),
	// EDITRELIGIOUSTOUR: lazy(() => import('../pages/pujaPathPages/religiousTour/EditReligiousTourPage')),

	// // order
	// ORDER: lazy(() => import('../pages/pujaPathPages/order/OrderPage')),
	// // order
	// ORDERPENDING: lazy(() => import('../pages/pujaPathPages/order/PendingPage')),
	// ORDERPROCESSING: lazy(() => import('../pages/pujaPathPages/order/ProcessingPage')),
	// ORDERCOMPLETED: lazy(() => import('../pages/pujaPathPages/order/CompletedPage')),
	// ORDERCANCELED: lazy(() => import('../pages/pujaPathPages/order/CanceledPage')),
	// EDITORDER: lazy(() => import('../pages/pujaPathPages/order/EditOrderPage')),
	// EDITPENDINGORDER: lazy(() => import('../pages/pujaPathPages/order/EditPendingOrderPage')),
	// VIEWORDER: lazy(() => import('../pages/pujaPathPages/order/ViewPage')),

	// //setting
	OURSERVICE: lazy(() => import('../pages/pujaPathPages/ourService/OurServicePage')),
	EDITSERVICE: lazy(() => import('../pages/pujaPathPages/ourService/EditOurServicePage')),
	// EDITLOCATION: lazy(() => import('../pages/pujaPathPages/setting/location/EditLocationPage')),

	// //label
	SOLUTION: lazy(() => import('../pages/pujaPathPages/solution/SolutionPage')),
	PROCESS: lazy(() => import('../pages/pujaPathPages/process/ProcessPage')),

	// users
	CLIENT: lazy(() => import('../pages/pujaPathPages/client/ClientPage')),
	ADDCLIENT: lazy(() => import('../pages/pujaPathPages/client/AddClientPage')),
	EDITCLIENT: lazy(() => import('../pages/pujaPathPages/client/EditClientPage')),
	// EDITPROFILE: lazy(() => import('../pages/pujaPathPages/user/UserProfileEdit')),

	//position
	POSITION: lazy(() => import('../pages/pujaPathPages/position/PositionPage')),
	ADDPOSITION: lazy(() => import('../pages/pujaPathPages/position/AddPositionPage')),
	EDITPOSITION: lazy(() => import('../pages/pujaPathPages/position/EditPositionPage')),

	// // users
	APPLICANT: lazy(() => import('../pages/pujaPathPages/applicant/ApplicantPage')),
	EDITAPPLICATION: lazy(() => import('../pages/pujaPathPages/applicant/EditApplicantPage')),

	//contact
	CONTACT: lazy(() => import('../pages/pujaPathPages/contact/ContactPage')),
	EDITCONTACT: lazy(() => import('../pages/pujaPathPages/contact/EditContactPage')),

	EDITSTORY: lazy(() => import('../pages/pujaPathPages/story/EditStoryPage')),
	// // subscription
	HOME: lazy(() => import('../pages/pujaPathPages/home/EditHomePage')),
	// EDITHOME: lazy(() => import('../pages/pujaPathPages/home/EditHomePage')),
	ABOUTUS: lazy(() => import('../pages/pujaPathPages/aboutUs/EditAboutUsPage')),
	CONTACTPAGE: lazy(() => import('../pages/pujaPathPages/contactPage/EditContactPage')),
	CAREERPAGE: lazy(() => import('../pages/pujaPathPages/careerPage/EditCareerPage')),
	EDITPROJECT: lazy(() => import('../pages/pujaPathPages/project/EditProjectPage')),
	
	// // user report
	PROJECT: lazy(() => import('../pages/pujaPathPages/project/ProjectPage')),
	ADDPROJECT: lazy(() => import('../pages/pujaPathPages/project/AddProjectPage')),
};



const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};


//  pages
const presentation: RouteProps[] = [
	/**
	 * Landing
	 */
	{
		path: dashboardPagesMenu.dashboard.path,
		// element: <MAINPAGES.DASHBOARD />,
	},
	
	

	/** ************************************************** */

	/**
	 * Pages
	 */

	/**
	 * Single Pages
	 */
	{
		path: demoPagesMenu.singlePages.subMenu.boxedSingle.path,
		// element: <SINGLE.BOXED />,
	},
	{
		path: demoPagesMenu.singlePages.subMenu.fluidSingle.path,
		// element: <SINGLE.FLUID />,
	},

	/**
	 * List
	 */
	{
		path: demoPagesMenu.listPages.subMenu.listBoxed.path,
		// element: <LIST.BOXED />,
	},
	{
		path: demoPagesMenu.listPages.subMenu.listFluid.path,
		// element: <LIST.FLUID />,
	},

	/**
	 * Grid
	 */
	{
		path: demoPagesMenu.gridPages.subMenu.gridBoxed.path,
		// element: <GRID.BOXED />,
	},
	{
		path: demoPagesMenu.gridPages.subMenu.gridFluid.path,
		// element: <GRID.FLUID />,
	},

	/**
	 * Edit
	 */
	// {
	// 	path: demoPagesMenu.editPages.subMenu.editModern.path,
	// 	element: <EDIT.MODERN />,
	// },
	// {
	// 	path: demoPagesMenu.editPages.subMenu.editBoxed.path,
	// 	element: <EDIT.BOXED />,
	// },
	// {
	// 	path: demoPagesMenu.editPages.subMenu.editFluid.path,
	// 	element: <EDIT.FLUID />,
	// },
	// {
	// 	path: demoPagesMenu.editPages.subMenu.editWizard.path,
	// 	element: <EDIT.WIZARD />,
	// },
	// {
	// 	path: demoPagesMenu.editPages.subMenu.editInCanvas.path,
	// 	element: <EDIT.IN_CANVAS />,
	// },
	// {
	// 	path: demoPagesMenu.editPages.subMenu.editInModal.path,
	// 	element: <EDIT.IN_MODAL />,
	// },

	// {
	// 	path: demoPagesMenu.pricingTable.path,
	// 	element: <PRICING.PRICING_TABLE />,
	// },

	/**
	 * END - Pages
	 */

	/**
	 * Auth Page
	 */
	{
		path: demoPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: demoPagesMenu.login.path,
		element: <Login />,
	},
	{
		path: demoPagesMenu.signUp.path,
		element: <Login isSignUp />,
	},
	{
		path: demoPagesMenu.start.path,
		// element: <MAINPAGES.DASHBOARD/>,
	},

	/**
	 * App
	 */

	/**
	 * App > Project Management
	 */
	// {
	// 	path: demoPagesMenu.projectManagement.subMenu.list.path,
	// 	element: <APP.PROJECT_MANAGEMENT.PROJECTS_LIST />,
	// },
	// {
	// 	path: `${demoPagesMenu.projectManagement.subMenu.itemID.path}/:id`,
	// 	element: <APP.PROJECT_MANAGEMENT.PROJECT />,
	// },

	/**
	 * App > Knowledge
	 */
// 	{
// 		path: demoPagesMenu.knowledge.subMenu.grid.path,
// 		element: <APP.KNOWLEDGE.GRID />,
// 	},
// 	{
// 		path: `${demoPagesMenu.knowledge.subMenu.itemID.path}/:id`,
// 		element: <APP.KNOWLEDGE.VIEW />,
// 	},

// 	/**
// 	 * App > Sales
// 	 */
// 	{
// 		path: demoPagesMenu.sales.subMenu.transactions.path,
// 		element: <APP.SALES.TRANSACTIONS />,
// 	},
// 	{
// 		path: demoPagesMenu.sales.subMenu.salesList.path,
// 		element: <APP.SALES.PRODUCTS />,
// 	},
// 	{
// 		path: demoPagesMenu.sales.subMenu.productsGrid.path,
// 		element: <APP.SALES.PRODUCTS_GRID />,
// 	},
// 	{
// 		path: `${demoPagesMenu.sales.subMenu.productID.path}/:id`,
// 		element: <APP.SALES.PRODUCTS_VIEW />,
// 	},

// 	/**
// 	 * App > Appointment
// 	 */
// 	{
// 		path: demoPagesMenu.appointment.subMenu.calendar.path,
// 		element: <APP.APPOINTMENT.CALENDAR />,
// 	},
// 	{
// 		path: demoPagesMenu.appointment.subMenu.employeeList.path,
// 		element: <APP.APPOINTMENT.EMPLOYEE_LIST />,
// 	},
// 	{
// 		path: `${demoPagesMenu.appointment.subMenu.employeeID.path}/:id`,
// 		element: <APP.APPOINTMENT.EMPLOYEE_VIEW />,
// 	},
// 	{
// 		path: demoPagesMenu.appointment.subMenu.appointmentList.path,
// 		element: <APP.APPOINTMENT.APPOINTMENT_LIST />,
// 	},

// 	/**
// 	 * App > CRM
// 	 */
// 	{
// 		path: demoPagesMenu.crm.subMenu.dashboard.path,
// 		element: <APP.CRM.CRM_DASHBOARD />,
// 	},
// 	{
// 		path: demoPagesMenu.crm.subMenu.customersList.path,
// 		element: <APP.CRM.CUSTOMERS />,
// 	},
// 	{
// 		path: `${demoPagesMenu.crm.subMenu.customerID.path}/:id`,
// 		element: <APP.CRM.CUSTOMER />,
// 	},

// 	/**
// 	 * App > Chat
// 	 */
// 	{
// 		path: demoPagesMenu.chat.subMenu.withListChat.path,
// 		element: <APP.CHAT.WITH_LIST />,
// 	},
// 	{
// 		path: demoPagesMenu.chat.subMenu.onlyListChat.path,
// 		element: <APP.CHAT.ONLY_LIST />,
// 	},

// 	/**
// 	 * END - App
// 	 */

// 	/** ************************************************** */

// 	/**
// 	 * Page Layout Types
// 	 */
// 	{
// 		path: pageLayoutTypesPagesMenu.blank.path,
// 		element: <PAGE_LAYOUTS.BLANK />,
// 	},
// 	{
// 		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.headerAndSubheader.path,
// 		element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
// 	},
// 	{
// 		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyHeader.path,
// 		element: <PAGE_LAYOUTS.HEADER />,
// 	},
// 	{
// 		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path,
// 		element: <PAGE_LAYOUTS.SUBHEADER />,
// 	},
// 	{
// 		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path,
// 		element: <PAGE_LAYOUTS.CONTENT />,
// 	},
// 	{
// 		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.defaultAside.path,
// 		element: <PAGE_LAYOUTS.ASIDE />,
// 	},
// 	{
// 		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.minimizeAside.path,
// 		element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
// 	},
// ];
// const documentation: RouteProps[] = [
// 	/**
// 	 * Getting Started
// 	 */
// 	{
// 		path: gettingStartedPagesMenu.gettingStarted.subMenu.installation.path,
// 		element: <GETTING_STARTED.INSTALLATION />,
// 	},
// 	{
// 		path: gettingStartedPagesMenu.gettingStarted.subMenu.dev.path,
// 		element: <GETTING_STARTED.DEVELOPMENT />,
// 	},
// 	{
// 		path: gettingStartedPagesMenu.gettingStarted.subMenu.folderStructure.path,
// 		element: <GETTING_STARTED.FOLDER />,
// 	},
// 	{
// 		path: gettingStartedPagesMenu.gettingStarted.subMenu.bootstrapVariables.path,
// 		element: <GETTING_STARTED.BOOTSTRAP />,
// 	},
// 	{
// 		path: gettingStartedPagesMenu.gettingStarted.subMenu.projectStructure.path,
// 		element: <GETTING_STARTED.PROJECT />,
// 	},
// 	/**
// 	 * Routes
// 	 */
// 	{
// 		path: gettingStartedPagesMenu.routes.subMenu.router.path,
// 		element: <ROUTES.ROUTER />,
// 	},
// 	/**
// 	 * Bootstrap
// 	 */

// 	/**
// 	 * Content
// 	 */
// 	{
// 		path: componentPagesMenu.content.path,
// 		element: <CONTENT.CONTENTS />,
// 	},
// 	{
// 		path: componentPagesMenu.content.subMenu.typography.path,
// 		element: <CONTENT.TYPOGRAPHY />,
// 	},
// 	{
// 		path: componentPagesMenu.content.subMenu.images.path,
// 		element: <CONTENT.IMAGES />,
// 	},
// 	{
// 		path: componentPagesMenu.content.subMenu.tables.path,
// 		element: <CONTENT.TABLES />,
// 	},
// 	{
// 		path: componentPagesMenu.content.subMenu.figures.path,
// 		element: <CONTENT.FIGURES />,
// 	},

// 	/**
// 	 * Forms
// 	 */
// 	{
// 		path: componentPagesMenu.forms.path,
// 		element: <FORMS_PAGE.FORMS />,
// 	},
// 	{
// 		path: componentPagesMenu.forms.subMenu.formGroup.path,
// 		element: <FORMS_PAGE.FORM_GROUP />,
// 	},
// 	{
// 		path: componentPagesMenu.forms.subMenu.formControl.path,
// 		element: <FORMS_PAGE.FORM_CONTROLS />,
// 	},
// 	{
// 		path: componentPagesMenu.forms.subMenu.select.path,
// 		element: <FORMS_PAGE.SELECT />,
// 	},
// 	{
// 		path: componentPagesMenu.forms.subMenu.checksAndRadio.path,
// 		element: <FORMS_PAGE.CHECKS_AND_RADIO />,
// 	},
// 	{
// 		path: componentPagesMenu.forms.subMenu.range.path,
// 		element: <FORMS_PAGE.RANGE />,
// 	},
// 	{
// 		path: componentPagesMenu.forms.subMenu.inputGroup.path,
// 		element: <FORMS_PAGE.INPUT_GROUP />,
// 	},
// 	{
// 		path: componentPagesMenu.forms.subMenu.validation.path,
// 		element: <FORMS_PAGE.VALIDATION />,
// 	},
// 	{
// 		path: componentPagesMenu.forms.subMenu.wizard.path,
// 		element: <FORMS_PAGE.WIZARD />,
// 	},

// 	/**
// 	 * Components
// 	 */
// 	{
// 		path: componentPagesMenu.components.path,
// 		element: <COMPONENTS_PAGE.COMPONENTS />,
// 	},
// 	{
// 		path: componentPagesMenu.components.subMenu.tooltip.path,
// 		element: <COMPONENTS_PAGE.TOOLTIP />,
// 	},
// 	{
// 		path: componentPagesMenu.components.subMenu.toasts.path,
// 		element: <COMPONENTS_PAGE.TOASTS />,
// 	},
// 	{
// 		path: componentPagesMenu.components.subMenu.scrollspy.path,
// 		element: <COMPONENTS_PAGE.SCROLLSPY />,
// 	},
// 	{
// 		path: componentPagesMenu.components.subMenu.carousel.path,
// 		element: <COMPONENTS_PAGE.CAROUSEL />,
// 	},
// 	{
// 		path: componentPagesMenu.components.subMenu.spinners.path,
// 		element: <COMPONENTS_PAGE.SPINNER />,
// 	},
// 	{
// 		path: componentPagesMenu.components.subMenu.listGroup.path,
// 		element: <COMPONENTS_PAGE.LIST_GROUP />,
// 	},
// 	{
// 		path: componentPagesMenu.components.subMenu.breadcrumb.path,
// 		element: <COMPONENTS_PAGE.BREADCRUMB />,
// 	},
// 	{
// 		path: componentPagesMenu.components.subMenu.collapse.path,
// 		element: <COMPONENTS_PAGE.COLLAPSE />,
// 	},
// 	{
// 		path: componentPagesMenu.components.subMenu.pagination.path,
// 		element: <COMPONENTS_PAGE.PAGINATION />,
// 	},
// 	{
// 		path: componentPagesMenu.components.subMenu.progress.path,
// 		element: <COMPONENTS_PAGE.PROGRESS />,
// 	},
// 	{
// 		path: componentPagesMenu.components.subMenu.card.path,
// 		element: <COMPONENTS_PAGE.CARD />,
// 	},
// 	{
// 		path: componentPagesMenu.components.subMenu.button.path,
// 		element: <COMPONENTS_PAGE.BUTTON />,
// 	},
// 	{
// 		path: componentPagesMenu.components.subMenu.buttonGroup.path,
// 		element: <COMPONENTS_PAGE.BUTTON_GROUP />,
// 	},
// 	{
// 		path: componentPagesMenu.components.subMenu.alert.path,
// 		element: <COMPONENTS_PAGE.ALERT />,
// 	},
// 	{
// 		path: componentPagesMenu.components.subMenu.badge.path,
// 		element: <COMPONENTS_PAGE.BADGE />,
// 	},
// 	{
// 		path: componentPagesMenu.components.subMenu.popovers.path,
// 		element: <COMPONENTS_PAGE.POPOVERS />,
// 	},
// 	{
// 		path: componentPagesMenu.components.subMenu.dropdowns.path,
// 		element: <COMPONENTS_PAGE.DROPDOWN />,
// 	},
// 	{
// 		path: componentPagesMenu.components.subMenu.accordion.path,
// 		element: <COMPONENTS_PAGE.ACCORDION />,
// 	},
// 	{
// 		path: componentPagesMenu.components.subMenu.modal.path,
// 		element: <COMPONENTS_PAGE.MODAL />,
// 	},
// 	{
// 		path: componentPagesMenu.components.subMenu.navsTabs.path,
// 		element: <COMPONENTS_PAGE.NAVS_TABS />,
// 	},
// 	{
// 		path: componentPagesMenu.components.subMenu.offcanvas.path,
// 		element: <COMPONENTS_PAGE.OFF_CANVAS />,
// 	},
// 	{
// 		path: componentPagesMenu.components.subMenu.table.path,
// 		element: <COMPONENTS_PAGE.TABLE />,
// 	},

// 	/**
// 	 * Utilities
// 	 */
// 	{
// 		path: componentPagesMenu.utilities.path,
// 		element: <UTILITIES.UTILITIES />,
// 	},
// 	{
// 		path: componentPagesMenu.utilities.subMenu.api.path,
// 		element: <UTILITIES.API />,
// 	},
// 	{
// 		path: componentPagesMenu.utilities.subMenu.background.path,
// 		element: <UTILITIES.BACKGROUND />,
// 	},
// 	{
// 		path: componentPagesMenu.utilities.subMenu.borders.path,
// 		element: <UTILITIES.BORDERS />,
// 	},
// 	{
// 		path: componentPagesMenu.utilities.subMenu.colors.path,
// 		element: <UTILITIES.COLORS />,
// 	},
// 	{
// 		path: componentPagesMenu.utilities.subMenu.display.path,
// 		element: <UTILITIES.DISPLAY />,
// 	},
// 	{
// 		path: componentPagesMenu.utilities.subMenu.flex.path,
// 		element: <UTILITIES.FLEX />,
// 	},
// 	{
// 		path: componentPagesMenu.utilities.subMenu.float.path,
// 		element: <UTILITIES.FLOAT />,
// 	},
// 	{
// 		path: componentPagesMenu.utilities.subMenu.interactions.path,
// 		element: <UTILITIES.INTERACTIONS />,
// 	},
// 	{
// 		path: componentPagesMenu.utilities.subMenu.overflow.path,
// 		element: <UTILITIES.OVERFLOW />,
// 	},
// 	{
// 		path: componentPagesMenu.utilities.subMenu.position.path,
// 		element: <UTILITIES.POSITION />,
// 	},
// 	{
// 		path: componentPagesMenu.utilities.subMenu.shadows.path,
// 		element: <UTILITIES.SHADOWS />,
// 	},
// 	{
// 		path: componentPagesMenu.utilities.subMenu.sizing.path,
// 		element: <UTILITIES.SIZING />,
// 	},
// 	{
// 		path: componentPagesMenu.utilities.subMenu.spacing.path,
// 		element: <UTILITIES.SPACING />,
// 	},
// 	{
// 		path: componentPagesMenu.utilities.subMenu.text.path,
// 		element: <UTILITIES.TEXT />,
// 	},
// 	{
// 		path: componentPagesMenu.utilities.subMenu.verticalAlign.path,
// 		element: <UTILITIES.VERTICAL_ALIGN />,
// 	},
// 	{
// 		path: componentPagesMenu.utilities.subMenu.visibility.path,
// 		element: <UTILITIES.VISIBILITY />,
// 	},

// 	/**
// 	 * Extra
// 	 */

// 	/**
// 	 * Icons
// 	 */
// 	{
// 		path: componentPagesMenu.icons.path,
// 		element: <ICONS.ICONS_LIST />,
// 	},
// 	{
// 		path: componentPagesMenu.icons.subMenu.icon.path,
// 		element: <ICONS.ICON />,
// 	},
// 	{
// 		path: componentPagesMenu.icons.subMenu.material.path,
// 		element: <ICONS.MATERIAL />,
// 	},

// 	/**
// 	 * Charts
// 	 */
// 	{
// 		path: componentPagesMenu.charts.path,
// 		element: <CHARTS_PAGE.CHART_LIST />,
// 	},
// 	{
// 		path: componentPagesMenu.charts.subMenu.chartsUsage.path,
// 		element: <CHARTS_PAGE.GENERAL_USAGE />,
// 	},
// 	{
// 		path: componentPagesMenu.charts.subMenu.chartsSparkline.path,
// 		element: <CHARTS_PAGE.SPARKLINE />,
// 	},
// 	{
// 		path: componentPagesMenu.charts.subMenu.chartsLine.path,
// 		element: <CHARTS_PAGE.LINE />,
// 	},
// 	{
// 		path: componentPagesMenu.charts.subMenu.chartsArea.path,
// 		element: <CHARTS_PAGE.AREA />,
// 	},
// 	{
// 		path: componentPagesMenu.charts.subMenu.chartsColumn.path,
// 		element: <CHARTS_PAGE.COLUMN />,
// 	},
// 	{
// 		path: componentPagesMenu.charts.subMenu.chartsBar.path,
// 		element: <CHARTS_PAGE.BAR />,
// 	},
// 	{
// 		path: componentPagesMenu.charts.subMenu.chartsMixed.path,
// 		element: <CHARTS_PAGE.MIXED />,
// 	},
// 	{
// 		path: componentPagesMenu.charts.subMenu.chartsTimeline.path,
// 		element: <CHARTS_PAGE.TIMELINE />,
// 	},
// 	{
// 		path: componentPagesMenu.charts.subMenu.chartsCandleStick.path,
// 		element: <CHARTS_PAGE.CANDLESTICK />,
// 	},
// 	{
// 		path: componentPagesMenu.charts.subMenu.chartsBoxWhisker.path,
// 		element: <CHARTS_PAGE.BOX_WHISKER />,
// 	},
// 	{
// 		path: componentPagesMenu.charts.subMenu.chartsPieDonut.path,
// 		element: <CHARTS_PAGE.PIE_DONUT />,
// 	},
// 	{
// 		path: componentPagesMenu.charts.subMenu.chartsRadar.path,
// 		element: <CHARTS_PAGE.RADAR />,
// 	},
// 	{
// 		path: componentPagesMenu.charts.subMenu.chartsPolar.path,
// 		element: <CHARTS_PAGE.POLAR />,
// 	},
// 	{
// 		path: componentPagesMenu.charts.subMenu.chartsRadialBar.path,
// 		element: <CHARTS_PAGE.RADIAL_BAR />,
// 	},
// 	{
// 		path: componentPagesMenu.charts.subMenu.chartsBubble.path,
// 		element: <CHARTS_PAGE.BUBBLE />,
// 	},
// 	{
// 		path: componentPagesMenu.charts.subMenu.chartsScatter.path,
// 		element: <CHARTS_PAGE.SCATTER />,
// 	},
// 	{
// 		path: componentPagesMenu.charts.subMenu.chartsHeatMap.path,
// 		element: <CHARTS_PAGE.HEAT_MAP />,
// 	},
// 	{
// 		path: componentPagesMenu.charts.subMenu.chartsTreeMap.path,
// 		element: <CHARTS_PAGE.TREE_MAP />,
// 	},

	{
		path: componentPagesMenu.notification.path,
		// element: <EXTRA.NOTIFICATION />,
	},
	{
		path: componentPagesMenu.hooks.path,
		// element: <EXTRA.HOOKS />,
	},
];


// PUJA PATH ADMIN PAGES
const pujaPathPages: RouteProps[] = [

	// main pages

	// Dashboard
	{
		// path: dashboardMenu.dashboard.path,
		// element: <MAINPAGES.DASHBOARD />,
	},
	{
		path: dashboardMenu.aboutUs.path,
		element: <MAINPAGES.ABOUTUS />,
	},
	{
		path: dashboardMenu.contactPage.path,
		element: <MAINPAGES.CONTACTPAGE />,
	},
	{
		path: dashboardMenu.careerPage.path,
		element: <MAINPAGES.CAREERPAGE />,
	},
	{
		path: dashboardMenu.solution.path,
		element: <MAINPAGES.SOLUTION />,
	},
	{
		path: dashboardMenu.process.path,
		element: <MAINPAGES.PROCESS />,
	},
	{
		path: dashboardMenuSubPages.AddTestimonial.path,
		element: <MAINPAGES.ADDTESTIMONIAL />,
	},
	{
		path: `${dashboardMenuSubPages.EditTestimonial.path}/:id`,
		element: <MAINPAGES.EDITTESTIMONIAL />,
	},
	{
		path: dashboardMenuSubPages.AddClient.path,
		element: <MAINPAGES.ADDCLIENT />,
	},
	{
		path: `${dashboardMenuSubPages.EditClient.path}/:id`,
		element: <MAINPAGES.EDITCLIENT />,
	},
	{
		path: dashboardMenuSubPages.AddProcessSlider.path,
		element: <MAINPAGES.ADDPROCESSSLIDER />,
	},
	{
		path: `${dashboardMenuSubPages.EditProcessSlider.path}/:id`,
		element: <MAINPAGES.EDITPROCESSSLIDER />,
	},
	{
		path: dashboardMenu.configuration.subMenu.testimonial.path,
		element: <MAINPAGES.TESTIMONIAL />,
	},
	{
		path: dashboardMenu.story.path,
		element: <MAINPAGES.EDITSTORY />,
	},
	{
		path: `${dashboardMenuSubPages.EditService.path}/:id`,
		element: <MAINPAGES.EDITSERVICE />,
	},
	{
		path: `${dashboardMenuSubPages.EditStory.path}/:id`,
		element: <MAINPAGES.EDITSTORY />,
	},
	{
		path: `${dashboardMenuSubPages.EditProject.path}/:id`,
		element: <MAINPAGES.EDITPROJECT />,
	},
	{
		path: dashboardMenuSubPages.AddProject.path,
		element: <MAINPAGES.ADDPROJECT />,
	},
	{
		path: dashboardMenu.configuration.subMenu.projects.path,
		element: <MAINPAGES.PROJECT />,
	},
	{
		path: dashboardMenu.configuration.subMenu.clients.path,
		element: <MAINPAGES.CLIENT />,
	},
	{
		path: dashboardMenu.configuration.subMenu.processSlider.path,
		element: <MAINPAGES.PROCESSSLIDER />,
	},
	// category
	{
		path: dashboardMenu.configuration.subMenu.categories.path,
		element: <MAINPAGES.CATEGORY />,
	},
	//applicant
	{
		path: dashboardMenu.configuration.subMenu.dashboard.path,
		element: <MAINPAGES.APPLICANT />,
	},
	
	// position
	{
		path: dashboardMenu.configuration.subMenu.positions.path,
		element: <MAINPAGES.POSITION />,
	},
	{
		path: dashboardMenu.home.path,
		element: <MAINPAGES.HOME />,
	},
	{
		path: dashboardMenu.configuration.subMenu.ourService.path,
		element: <MAINPAGES.OURSERVICE />,
	},
	{
		path: dashboardMenuSubPages.AddPosition.path,
		element: <MAINPAGES.ADDPOSITION />,
	},
	{
		path: `${dashboardMenuSubPages.EditPosition.path}/:id`,
		element: <MAINPAGES.EDITPOSITION />,
	},
	{
		path: dashboardMenuSubPages.AddCategory.path,
		element: <MAINPAGES.ADDCATEGORY />,
	},
	{
		path: `${dashboardMenuSubPages.EditCategory.path}/:id`,
		element: <MAINPAGES.EditCATEGORY />,
	},
	{
		path: `${dashboardMenuSubPages.EditContact.path}/:id`,
		element: <MAINPAGES.EDITCONTACT />,
	},
	{
		path: dashboardMenu.configuration.subMenu.contact.path,
		element: <MAINPAGES.CONTACT />,
	},
	{
		path: dashboardMenu.Setting.path,
		element: <MAINPAGES.SETTING />,
	},
	{
		path: `${dashboardMenuSubPages.EditApplication.path}/:id`,
		element: <MAINPAGES.EDITAPPLICATION />,
	},
];


const contents = [...presentation, ...pujaPathPages]; //...documentation

export default contents;
