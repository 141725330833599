import axios from "axios";
const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});

let AUTH_TOKEN = typeof window !== 'undefined' ? localStorage.getItem('authToken') : ''


let token = AUTH_TOKEN ? `Bearer ${AUTH_TOKEN}` : ''
let common = {
    //"Authorization": "AUTH_TOKEN",
    "Authorization": token,
    "Content-type": "application/json"
}

// Alter defaults after instance has been created
api.defaults.headers.common = common;
api.defaults.timeout = 2500; // most apis should not require more than 2.5 seconds. Even this is too much.



// using interceptors for debugging while in debug mode
// Add a request interceptor
if(process.env.REACT_APP_ENVIRONMENT == 'development'){

    api.interceptors.request.use(function (config) {
        // Do something before request is sent
    
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });
    
    // Add a response interceptor
    api.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        
        return response;
    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    });
}


export default api;
