import React, { FC } from 'react';
import PropTypes from 'prop-types';
import logo from './../assets/img/newLogo.svg';

interface ILogoProps {
	width?: number;
	height?: number;
}

const Logo: FC<ILogoProps> = ({ width, height }) => {
	return (
		<>
		<img src={logo} height={65} width={150} className='mt-4'/>
		</>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
